import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import HeadTwo from "../../components/HeadTwo/HeadTwo";
import Layout from "../../components/Layout";
import Benefits from "../../components/pages/partners/Benefits";
import PartnerFooter from "../../components/pages/partners/Footer";
import PartnerRoleHero from "../../components/pages/partners/RoleHero";
import PartnerSteps from "../../components/pages/partners/Steps";

const heroData = {
  image: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/eaaed3b1-6c61-4c2f-85f6-d4ff9f6dcfb0.png'
      alt=''
    />
  ),
  mobileImage: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/cf75eb44-ff9d-4646-bee0-98e38c53b7ca.png'
      alt=''
    />
  ),
  content: (
    <div>
      <p>
        The eCommerce ecosystem runs on support from agencies & consultants
        (like you!) who ensure the "always-on" in an always-on business. And we
        can help you optimize that! We love building seamless shopping
        experiences through our no-code, automated playbooks.
      </p>

      <p className='purpleB'>
        So, let's work together to <b>empower your clients</b> nail CRO, get
        happier customers, and drive sales.
      </p>

      <p className='purpleB'>
        Our <b>Mason Agency Program</b> is designed to help your clients (and
        your business!) grow with us!
      </p>
    </div>
  ),
};

const benifits = [
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/54d18e19-af53-41ff-acc0-ec38848b1d19.png'
        alt='benifit'
      />
    ),
    color: "#8A3063",
    title: "Revenue Sharing",
    p1: <p>20% of the month-on-month revenue for every new user.</p>,
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/7359f41c-fd50-4bf6-87a7-27b4f397d847.png'
        alt='benifit'
      />
    ),
    color: "#8A3063",
    title: "Priority Support",
    p1: <p>Dedicated Account Manager to support you and your clients.</p>,
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/3918f699-80e8-4a8f-b29e-a0f76eb41ffe.png'
        alt='benifit'
      />
    ),
    color: "#8A3063",
    title: "Co-marketing",
    p1: (
      <p>
        Events, content, and more collaborative initiatives to drive brand
        presence.
      </p>
    ),
  },
];

const steps = [
  "Fill out the partner application form. (It take ~30 seconds!)",
  "Get matched with a dedicated account manager to onboard you.",
  "Get your preferred code within 24 hours.",
  "Let's start helping your clients (and revenue!) grow together.",
];

const agency = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];

  return (
    <Layout seoData={seoData}>
      <PartnerRoleHero {...heroData} />
      <div className='mt-24'>
        <HeadTwo>
          Tech Partner <b>benefits</b>
        </HeadTwo>
      </div>
      <Benefits data={benifits} />
      <PartnerSteps data={steps} />
      <PartnerFooter color='#8A3063' />
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "agency"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default agency;
